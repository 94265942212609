import { defineStore } from 'pinia';
import { getStorage } from './storage/index';
import { computed, ref } from 'vue';

export type UserStore = Omit<
    ReturnType<typeof useUserStore>,
    keyof ReturnType<typeof defineStore>
>;

export interface UserInfo {
    email: string;
    roles: string[];
    has_local_account: Boolean;
    admin_username?: string;
    return_to?: string;
    name: string;
}

export const useUserStore = defineStore('user', () => {
    const accessToken = ref<string>();
    const refreshToken = ref<string>();
    const userInfo = ref<UserInfo>();

    const isAuthenticated = computed(() => accessToken.value != null);

    const hasLocalAccount = computed(() => isAuthenticated.value && userInfo.value != null && userInfo.value.has_local_account);

    const isLoggedInAsAnotherUser = computed(() => isAuthenticated.value && userInfo.value != null && userInfo.value.admin_username != null);

    function isInRole(roles: string | string[]) {
        if (!userInfo.value)
            return false;

        const rolesToMatch = typeof (roles) === 'string' ? [roles] : roles;

        return userInfo.value.roles.some((r) => rolesToMatch.includes(r));
    }

    function reset() {
        accessToken.value = undefined;
        refreshToken.value = undefined;
        userInfo.value = undefined;
    }

    return { accessToken, refreshToken, userInfo, isAuthenticated, hasLocalAccount, isLoggedInAsAnotherUser, isInRole, reset };
},
    {
        persist: {
            storage: getStorage('localStorage')
        }
    });