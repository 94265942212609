﻿<template>
    <NuxtLayout>
        <div class="body-content">
            <div class="page page-narrow">
                <div class="page-body text-center">
                    <template v-if="error.statusCode === 404">
                        <div class="page-header">
                            <h1>404 Page Not Found</h1>
                        </div>
                        <p>This page is not found.</p>
                    </template>
                    <template v-else-if="error.statusCode === 401">
                        <div class="page-header">
                            <h1>401 Forbidden</h1>
                        </div>
                        <p>You are not authorized to access this page.</p>
                    </template>
                    <template v-else>
                        <div class="page-header">
                            <h1>{{ error.statusCode }} {{ error.statusMessage ?? error.message }}</h1>
                        </div>

                        <p>{{ error.statusMessage ?? error.message }}</p>
                    </template>
                    <BButton @click="handleError" class="mt-4">Go to the homepage</BButton>
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<script setup lang="ts">
    import type { NuxtError } from '#app';

    const props = defineProps({
        error: Object as () => NuxtError
    });

    const handleError = () => { clearError({ redirect: '/' }); };
</script>