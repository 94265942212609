export function getStorage(storageType: string) {
    switch (storageType) {
        case 'cookieStorage':
            return persistedState.cookiesWithOptions({
                sameSite: 'strict',
                domain: window.location.hostname,
                path: '/',
                secure: false
            });

        case 'sessionStorage':
            return persistedState.sessionStorage;

        default:
            return persistedState.localStorage;
    }
}