import { useUserStore } from '@/stores/userStore';
import authDefaults from '@/constants/auth-constants';
import Cookies from "js-cookie";
import type { RefreshTokenModel } from '@/types/models/auth/refresh-token-model';
import type { TokenModel } from '~/types/models/auth/token-model';
import ApiError from '@/utils/api-error';
import _ from 'lodash';
import qs from "qs";

export const useAuth = () => {
    const userStore = useUserStore();

    async function login(username: string, password: string, rememberMe: boolean, redirect: string) {
        const data: TokenModel = {
            grant_type: 'password',
            username: username,
            password: password,
            scope: authDefaults.scope
        };

        await _login(data, rememberMe, redirect);
    }

    async function updateUserInfo() {
        try {
            const response = await useNuxtApp().$api(authDefaults.userInfoUri, { ...{ baseURL: '' }, method: 'GET' });
            userStore.userInfo = !_.isEmpty(response) ? response : null;
        }
        catch (error) {
            throw new ApiError(error);
        }
    }

    async function logout() {
        if (userStore.accessToken)
            await useNuxtApp().$api(authDefaults.logoutUri, { ...{ baseURL: '' }, method: 'GET' });

        endSession();
    }

    async function _login(data: TokenModel, rememberMe: boolean, redirect: string) {
        try {
            const response = await useNuxtApp().$api(authDefaults.tokenUri, { ...getUrlEncodedContentType(data, { baseURL: '' }), method: 'POST' });

            storeToken(response);

            if (rememberMe)
                Cookies.set('userSessionMarker', 'userSessionMarker', { expires: authDefaults.rememberMeDuration });
            else
                Cookies.set('userSessionMarker', 'userSessionMarker');

            await updateUserInfo();

            if (redirect)
                navigateTo(redirect);
        }
        catch (error) {
            throw new ApiError(error);
        }
    }

    function endSession(redirectToLogoutRoute = true) {
        Cookies.remove('userSessionMarker');

        userStore.reset();

        if (redirectToLogoutRoute)
            navigateTo(authDefaults.logoutRedirectTo);
    }

    function storeToken(response: any) {
        userStore.$patch({
            accessToken: response.access_token,
            refreshToken: response.refresh_token || null
        });
    }

    function ensureDataIntegrity() {
        if (!userStore.accessToken && Cookies.get('userSessionMarker'))
            return false;

        if (userStore.accessToken && !Cookies.get('userSessionMarker'))
            return false;

        return true;
    }

    function tokenExists() {
        return ensureDataIntegrity() && userStore.accessToken;
    }

    async function refreshToken(request: any, config: any) {
        const data = {
            grant_type: 'refresh_token',
            'refresh_token': userStore.refreshToken
        } as RefreshTokenModel;

        try {
            let response = await useNuxtApp().$api(authDefaults.tokenUri, { ...getUrlEncodedContentType(data, { baseURL: '' }), method: 'POST' });

            storeToken(response);

            await updateUserInfo();

            return await retryAfterTokenRefresh(request, config);
        }
        catch (error) {
            endSession();
            throw error;
        }
    }

    async function retryAfterTokenRefresh(request: any, config: any) {
        setAuthHeader(config);

        const method = config.method;
        //@ts-ignore
        return await useNuxtApp().$api(request, { ...config, method });
    }

    function isInvalidToken(response: any) {
        if (!response)
            return false;

        if (!response.headers)
            return false;

        const status = response.status;
        const wwwAuthenticateHeader = response.headers.get('www-authenticate');

        return (status === 401 && wwwAuthenticateHeader && (wwwAuthenticateHeader.includes('invalid_token') || wwwAuthenticateHeader.includes('expired_token')));
    }

    function setAuthHeader(config: any) {
        config.headers['Authorization'] = 'Bearer ' + userStore.accessToken;
    }

    function getUrlEncodedContentType(data: any, options: any) {
        const body = qs.stringify(data);

        options.headers ||= {};

        options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

        return { ...options, body };
    }
    function isRouteAccessible(route: any, userStore: any, tokenExists: any) {
        if (route.meta && route.meta.auth) {
            if (!userStore.isAuthenticated)
                return false;

            if (!tokenExists())
                return false;

            if (route.meta.auth.roles)
                return userStore.isInRole(route.meta.auth.roles);
        }

        return true;
    }

    return {
        login,
        logout,
        ensureDataIntegrity,
        endSession,
        tokenExists,
        refreshToken,
        isInvalidToken,
        setAuthHeader,
        updateUserInfo,
        isRouteAccessible,
        authDefaults
    }
};