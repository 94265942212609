import { useToast } from '@/composables/useToast';
import { useAuth } from '@/composables/useAuth';
import { defineNuxtPlugin, navigateTo } from '#app';

export default defineNuxtPlugin(() => {
    const { tokenExists, refreshToken, setAuthHeader, isInvalidToken } = useAuth();

    const api = $fetch.create({
        baseURL: '/api',

        onRequest({ request, options, error }: any) {
            if (tokenExists()) {
                options.headers = {
                    ...options.headers
                };

                setAuthHeader(options);
            }
        },

        async onResponseError({ request, response, options }: any) : Promise<any> {
            if (isInvalidToken(response))
                return refreshToken(request, options);

            if (response) {
                const { errorToast } = useToast();

                switch (response.status) {
                    case 400:
                        if (!responseHasCustomErrors(response))
                            errorToast('Oops! ' + response.statusText);
                        break;

                    case 401:
                        navigateTo('/error-401-forbidden');
                        break;

                    case 404:
                        navigateTo('/error-404-not-found');
                        break;

                    case 500:
                        if (response._data && response._data.error)
                            errorToast('Oops! ' + response._data.error);
                        else
                            errorToast('Oops! ' + response.statusText);
                        break;

                    default:
                        errorToast('Oops! ' + response.statusText);
                        break;
                }
            }

            return Promise.reject(response);
        }
    }) as any;

    const responseHasCustomErrors = (response: any): string => {
        const data = response?._data as any;

        return data.error_description || data.error;
    };

    return {
        provide: {
            api
        }
    }
})