import revive_payload_client_4sVQNw7RlN from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "C:/Projects/HUB/src/PublicSite/client-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import fontawesome_7xXmPH2btU from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/@vesp/nuxt-fontawesome/dist/runtime/plugins/fontawesome.js";
import useDirectives_W0tXrzvhuP from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/useDirectives.mjs";
import createBootstrap_ywvW1KIeJ9 from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/@bootstrap-vue-next/nuxt/dist/runtime/createBootstrap.mjs";
import plugin_AUP22rrBAc from "C:/Projects/HUB/src/PublicSite/client-app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "C:/Projects/HUB/src/PublicSite/client-app/plugins/api.ts";
import emitter_eaMgM0oZTb from "C:/Projects/HUB/src/PublicSite/client-app/plugins/emitter.ts";
import vue_toastificaton_client_LW56lxPlUl from "C:/Projects/HUB/src/PublicSite/client-app/plugins/vue-toastificaton.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  fontawesome_7xXmPH2btU,
  useDirectives_W0tXrzvhuP,
  createBootstrap_ywvW1KIeJ9,
  plugin_AUP22rrBAc,
  api_GFfDXud5Cr,
  emitter_eaMgM0oZTb,
  vue_toastificaton_client_LW56lxPlUl
]